import { RuntimeConfig } from "nuxt/schema";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useUserProfileStore } from "@/store/userProfileStore";
import { useNuxtApp } from "nuxt/app";


// @ts-ignore
const runtimeConfig: RuntimeConfig = useRuntimeConfig();
const customAxios = axios.create({
  baseURL: runtimeConfig.public.baseUrl as string
});

customAxios.interceptors.request.use(request => {
  const userProfile = useUserProfileStore();
  const isApiUrl = request.url?.startsWith(process.env.VUE_APP_API_PREFIX ?? "");
  // add auth header with jwt if account is logged in and request is to the api url
  if (isApiUrl && userProfile.accessToken) {
    request.headers.Authorization = `Bearer ${userProfile.accessToken}`;
  }

  return request;
});

customAxios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const { $trackEvent } = useNuxtApp();
    $trackEvent({
      name: "Error",
      properties: {
        message: error.message,
        status: error.response?.status,
        statusText: error.response?.statusText,
        url: error.config.url
      }
    });
    if (error.response.status === 401) {
      let loginUrl = error.response.data?.loginUrl;
      if (loginUrl) {
        loginUrl = loginUrl + "?returnUrl=" + window.location.pathname + window.location.search;
        window.location.href = loginUrl;
      }
      else {
        throw new Error("Unauthorized request");
      }
    } else {
      throw new Error(error);
    }
  }
});

export const httpRequestServer = {
  get: (url: string, configOptions?: AxiosRequestConfig): Promise<any> => {
    return customAxios.get(url, configOptions).then((response: AxiosResponse) => {
      return response?.data;
    });
  },
  post: (
    url: string,
    body: { [key: string]: number | string | boolean },
    configOptions?: AxiosRequestConfig
  ): Promise<any> => {
    return customAxios.post(url, body, configOptions).then((response: AxiosResponse) => {
      return response?.data;
    });
  }
};